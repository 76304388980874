import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { ToastProvider } from 'react-toast-notifications'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ToastProvider>
    <React.Fragment>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.Fragment>
  </ToastProvider>
);

serviceWorker.unregister()
