import React, { useEffect, useState } from "react";
import TableContainer from "components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import Appconfig from "constants/config"
import { useToasts } from "react-toast-notifications"
import { capitalize } from "lodash";
import moment from "moment";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Table
} from "reactstrap";

const Bookings = props => {
  document.title = "Bookings | FindMyChef";
  const { addToast } = useToasts();
  const [bookings, setBookings] = useState([]);
  const [modal, setmodal] = useState(false);

  function toggle(_id) {
    console.log("id_del", _id)
    setmodal(!modal);

  }

  const getChefs = async () => {
    try {
      const { data } = await axios.get(`${Appconfig.baseurl}/bookings/all`);
      if (!data.error) {
        setBookings(data && data.bookingsData && data.bookingsData.length > 0 ? data.bookingsData : [])
      } else {
        addToast(data.title || "Something went wrong", { appearance: 'error', autoDismiss: true });
      }
    } catch (error) {
      addToast(error.message || "Something went wrong", { appearance: 'error', autoDismiss: true });
    }
  }

  useEffect(() => {
    getChefs()
  }, [])

  const columns = [
    {
      Header: "_id",
      accessor: "_id",
    },
    {
      Header: "Customer",
      accessor: "user_id.name",
    },
    {
      Header: "Chef",
      accessor: "chef_id.name",
    },
    {
      Header: "Location",
      accessor: "location.name",
    },
    {
      Header: "Service date",
      accessor: 'date',
      accessor: (cellProps) => (
        moment(cellProps.date).format('DD-MM-YYYY')
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      accessor: (cellProps) => (
        capitalize(cellProps.status)
      ),
    },
    // {
    //   Header: "Action",
    //   // accessor: "status",
    //   accessor: (cellProps) => (
    //     // capitalize(cellProps.status)
    //     <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" title={"View Interested"}>
    //       <i className='bx bxs-show ' style={{ fontSize: '20px', marginRight: '5px' }} > </i>
    //     </span>
    //   ),
    // }
  ]

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs breadcrumbItem="Bookings" />
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={bookings}
                  hiddenColumns={["_id"]}
                  className="custom-header-css"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal className="modal-dialog-centered"
        isOpen={modal}
        toggle={() => {
          toggle();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Blocked Cleaner
          </h5>
          <button
            type="button"
            onClick={() => {
              toggle();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">

          <p>
            Are you sure you want to block this customer?
          </p>

        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              toggle();
            }}
            className="btn btn-primary "
            data-dismiss="modal"
          >
            No
          </button>
          <button
            onClick={() => blockedCustomer(id)}
            type="button"
            className="btn btn-danger "
          >
            Yes
          </button>
        </div>
      </Modal>

    </div>
  );
};

export default Bookings;
