import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TableContainer from "components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal";
import axios from "axios"
import Appconfig from "constants/config"
import { useToasts } from "react-toast-notifications"
import EditChef from "./editChef";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Table
} from "reactstrap";

const Chefs = props => {
  document.title = "Chefs | FindMyChef";
  const { addToast } = useToasts();
  const [chefs, setChefs] = useState([]);
  const [selectedChef, setSelectedChef] = useState({})
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [modal, setmodal] = useState(false);
  const [id, setId] = useState();
  const [blocked, setBlocked] = useState(false);


  function toggle() {
    setmodal(!modal);
  }

  const toggleEdit = () => {
    setEditOpen(false)
    setSelectedChef({})
  }

  const toggleDelete = () => {
    setDeleteOpen(false)
    setSelectedChef({})
  }
  const blockedChef = async (id) => {
    let is_blocked = blocked
    let values = { id, is_blocked }
    try {
      const { data } = await axios.post(`${Appconfig.baseurl}/chefs/blocked`, values);
      if (!data.error) {
        await addToast("Chef edited successfully", { appearance: 'success', autoDismiss: true });
        toggle()
        getChefs()
      } else {
        addToast(data.title || "Something went wrong", { appearance: 'error', autoDismiss: true });
      }
    } catch (error) {
      addToast(error.message || "Something went wrong", { appearance: 'error', autoDismiss: true });
    }
  }
  const handleDeleteUser = async () => {
    try {
      const { data } = await axios.post(`${Appconfig.baseurl}/chefs/delete`, { id: selectedChef._id }, {
        headers: { token: localStorage.getItem("token") }
      });
      if (!data.error) {
        addToast("Chef deleted successfully", { appearance: 'success', autoDismiss: true });
        getChefs()
        setDeleteOpen(false)
      } else {
        addToast(data.title || "Something went wrong", { appearance: 'error', autoDismiss: true });
      }
    } catch (error) {
      addToast(error.message || "Something went wrong", { appearance: 'error', autoDismiss: true });
    }
  }

  const getChefs = async () => {
    try {
      const { data } = await axios.get(`${Appconfig.baseurl}/chefs?perPage=100000&admin=${true}`);
      if (!data.error) {
        setChefs(data && data.chefsData && data.chefsData[0] && data.chefsData[0].data && data.chefsData[0].data.length > 0 ? data.chefsData[0].data : [])
      } else {
        addToast(data.title || "Something went wrong", { appearance: 'error', autoDismiss: true });
      }
    } catch (error) {
      addToast(error.message || "Something went wrong", { appearance: 'error', autoDismiss: true });
    }
  }

  useEffect(() => {
    getChefs()
  }, [])

  const columns = [
    {
      Header: "_id",
      accessor: "_id",
    },
    {
      Header: "Profile",
      accessor: "profile_img",
      disableFilters: true,
      filterable: true,
      accessor: (cellProps) => (
        <>
          {!cellProps.profile_img ? (
            <div className="avatar-xs">
              <span className="avatar-title rounded-circle">
                {cellProps.name.charAt(0)}
              </span>
            </div>
          ) : (
            <div>
              <img
                className="rounded-circle avatar-xs"
                src={cellProps.profile_img}
                alt=""
              />
            </div>
          )}
        </>
      ),
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Phone",
      accessor: "phone_no",
    },
    {
      Header: "Location",
      accessor: "location.name",
    },
    {
      Header: "Rate per Visit",
      accessor: "visit_rate",
    },

    {
      Header: "Action",
      accessor: (cellProps) => (
        <span className="d-inline-block" tabIndex="0" data-toggle="tooltip">
          {cellProps.is_blocked ? <button className="btn btn-md btn-primary " onClick={() => {
            toggle(); setId(cellProps._id); setBlocked(false)
          }} >
            Unblock
          </button> : <button className="btn btn-md btn-danger " onClick={() => {
            toggle(); setId(cellProps._id); setBlocked(true);
          }} >
            Block
          </button>}

        </span>
      ),
    }
    // {
    //   Header: "Action",
    //   Cell: ({ row }) => {
    //     return (
    //       <div className="d-flex gap-3">
    //         <Link
    //           to="#"
    //           className="text-success"
    //           onClick={() => {
    //             const foundChef = chefs.filter(v => String(v._id) == String(row.values._id))
    //             setSelectedChef(foundChef[0])
    //             setEditOpen(true)
    //           }}
    //         >
    //           <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
    //           <UncontrolledTooltip placement="top" target="edittooltip">
    //             Edit
    //           </UncontrolledTooltip>
    //         </Link>
    //         <Link
    //           to="#"
    //           className="text-danger"
    //           onClick={() => {
    //             const foundChef = chefs.filter(v => String(v._id) == String(row.values._id))
    //             setSelectedChef(foundChef[0])
    //             setDeleteOpen(true)
    //           }}
    //         >
    //           <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
    //           <UncontrolledTooltip placement="top" target="deletetooltip">
    //             Delete
    //           </UncontrolledTooltip>
    //         </Link>
    //       </div>
    //     );
    //   },
    // },
  ]

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs breadcrumbItem="Chefs" />
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={chefs}
                  hiddenColumns={["_id"]}
                  className="custom-header-css"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal className="modal-dialog-centered"
        isOpen={modal}
        toggle={() => {
          toggle();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {blocked ? 'Blocked' : 'Unblocked'} chef
          </h5>
          <button
            type="button"
            onClick={() => {
              toggle();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">

          <p>
            Are you sure you want to {blocked ? 'block' : 'unblock'} this chef?
          </p>

        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              toggle();
            }}
            className="btn btn-primary "
            data-dismiss="modal"
          >
            No
          </button>
          <button
            onClick={() => blockedChef(id)}
            type="button"
            className="btn btn-danger "
          >
            Yes
          </button>
        </div>
      </Modal>
      <DeleteModal show={deleteOpen} onDeleteClick={handleDeleteUser} onCloseClick={toggleDelete} />
      <EditChef chef={selectedChef} toggle={toggleEdit} isOpen={editOpen} getChefs={getChefs} />
    </div>
  );
};

export default Chefs;
