import React, { useEffect, useState } from "react";
import TableContainer from "components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import Appconfig from "constants/config"
import { useToasts } from "react-toast-notifications"
import { capitalize } from "lodash";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Table
} from "reactstrap";

const Jobs = props => {
  document.title = "Jobs | FindMyChef";
  const { addToast } = useToasts();
  const [jobs, setJobs] = useState([]);
  const [modal, setmodal] = useState(false);
  const [applicantData, setApplicantData] = useState();
  const [ChefList, setChefList] = useState();



  function toggle(_id) {
    console.log("id_del", _id)
    setmodal(!modal);

  }

  const getChefs = async () => {
    try {
      const { data } = await axios.get(`${Appconfig.baseurl}/jobs?perPage=100000`);
      if (!data.error) {
        setJobs(data && data.jobsData && data.jobsData[0] && data.jobsData[0].data && data.jobsData[0].data.length > 0 ? data.jobsData[0].data : [])
      } else {
        addToast(data.title || "Something went wrong", { appearance: 'error', autoDismiss: true });
      }
    } catch (error) {
      addToast(error.message || "Something went wrong", { appearance: 'error', autoDismiss: true });
    }
  }
  const getChefsInterested = async () => {
    try {
      const { data } = await axios.get(`${Appconfig.baseurl}/job_interest/chef_interests_per_job_admin`);
      if (!data.error) {
        setApplicantData(data && data.interestData)
      } else {
        addToast(data.title || "Something went wrong", { appearance: 'error', autoDismiss: true });
      }
    } catch (error) {
      addToast(error.message || "Something went wrong", { appearance: 'error', autoDismiss: true });
    }
  }

  useEffect(() => {
    getChefs();
    getChefsInterested()
  }, [])

  const columns = [
    {
      Header: "_id",
      accessor: "_id",
    },
    {
      Header: "Position",
      accessor: "position",
      accessor: (cellProps) => {
        const formatted = cellProps.position && cellProps.position.map((v) => capitalize(v.replace("_", " ")))
        return formatted && formatted.length > 0 ? formatted.join(", ") : ''
      },
    },
    {
      Header: "Location",
      accessor: "location.name",
    },
    {
      Header: "Salary",
      accessor: "salary",
    },
    {
      Header: "Experience (Yr)",
      accessor: "experience",
    },
    {
      Header: "Status",
      accessor: "status",
      accessor: (cellProps) => (
        capitalize(cellProps.status)
      ),
    },
    {
      Header: "Action",
      // accessor: "status",
      accessor: (cellProps) => (
        // capitalize(cellProps.status)
        <button
            type="button"
            onClick={() => InterstedChefsPerJobs(cellProps)}
            className="btn btn-primary "
            data-dismiss="modal"
          >
           View interested
          </button>
        // <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" title={"View Interested"}>
        //   <i className='bx bxs-show ' style={{ fontSize: '20px', marginRight: '5px' }} onClick={() => InterstedChefsPerJobs(cellProps)}> </i>
        // </span>
      ),
    }
  ]


  const InterstedChefsPerJobs = async (jobData) => {
    const filteredChefs = await applicantData.filter((chef) => chef.job_id === jobData._id)
    setChefList(filteredChefs)
    toggle()
  }
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs breadcrumbItem="Jobs" />
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={jobs}
                  hiddenColumns={["_id"]}
                  className="custom-header-css"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal className="modal-dialog-centered"
        isOpen={modal}
        toggle={() => {
          toggle();
        }}
      >
        <ModalHeader toggle={() =>  toggle()} tag="h4">
        Interested chefs
        </ModalHeader>
        <ModalBody style={{ marginLeft: '10px' }}>
          <div className="table-responsive">
            <Table className="table-nowrap mb-0">
              <tbody>
                {
                  ChefList && ChefList.length > 0 ? ChefList.map((val, i) => {
                    return <>
                      <tr>
                        <th scope="row"> Name :</th>

                        <td style={{ textTransform: "capitalize" }}>{i + 1} . {val.chef_id.name}  </td>
                      </tr>
                      <tr>
                        <th scope="row"> Email:</th>

                        <td style={{ textTransform: "capitalize" }}>{val.chef_id.email}</td>
                      </tr>
                      <tr>
                        <th scope="row"> Visit Rate:</th>
                        <td style={{ textTransform: "capitalize" }}>{val.chef_id.visit_rate}</td>
                      </tr>
                      <tr>
                      <hr style={{width:'100%'}}/>
                      </tr> 
                    </>
                  }) : <div className='w-[400px] mt-2'>
                    No chef interested in job.
                  </div>
                }
              </tbody>

            </Table>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Jobs;
