import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>
            <a href="https://www.mykitchenstaff.com/terms-of-service">
              <span style={{color:'black'}}  className="ml-2">
                Terms
              </span>
              </a>
              <a style={{marginLeft:'10px'}} href="https://www.mykitchenstaff.com/privacy-policy">
              <span style={{color:'black'}}>
                Privacy
              </span>
              </a>
            
            </Col>
            <Col md={6}>
              {/* <div className="text-sm-end d-none d-sm-block">
                Design & Develop by Themesbrand
              </div> */}
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
