import React, { useEffect, useState } from "react"
import { Container, Row, UncontrolledTooltip } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import DatatableTables from "components/Tables/DatatableTables"
import axios from "axios"
import Appconfig from "constants/config"
import { useToasts } from "react-toast-notifications"
import {
  Modal,
  ModalBody,
  ModalHeader,
  Table
} from "reactstrap";

const customer = props => {
  document.title = "Customers | FindMyChef";
  const { addToast } = useToasts();
  const [customers, setCustomers] = useState([])
  const [modal, setmodal] = useState(false);
  const [id, setId] = useState();
  const [blocked, setBlocked] = useState(false);


  function toggle() {
    setmodal(!modal);
  }

  const getCustomers = async () => {
    try {
      const { data } = await axios.get(`${Appconfig.baseurl}/users?perPage=100000`);
      if (!data.error) {
        setCustomers(data && data.usersData && data.usersData[0] && data.usersData[0].data && data.usersData[0].data.length > 0 ? data.usersData[0].data : [])
      } else {
        addToast(data.title || "Something went wrong", { appearance: 'error', autoDismiss: true });
      }
    } catch (error) {
      addToast(error.message || "Something went wrong", { appearance: 'error', autoDismiss: true });
    }
  }
  const blockedCustomer = async (id) => {
    let is_blocked=blocked
    let values ={id,is_blocked}
    try {
      const  {data}  = await axios.post(`${Appconfig.baseurl}/users/blocked`,values);
      if (!data.error) {
        addToast( "Customer edited successfully", { appearance: 'success', autoDismiss: true });
        toggle()
        getCustomers()
      } else {
        addToast(data.title || "Something went wrong", { appearance: 'error', autoDismiss: true });
      }
    } catch (error) {
      addToast(error.message || "Something went wrong", { appearance: 'error', autoDismiss: true });
    }
  }

  useEffect(() => {
    getCustomers()
  }, [])

  const columns = [
    {
      Header: '_id',
      accessor: '_id',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Email',
      accessor: 'email'
    },
    {
      Header: 'Phone',
      accessor: 'phone_no'
    },
    {
      Header: 'Location',
      accessor: 'location.name'
    },
    {
      Header: "Action",
      // accessor: "status",
      accessor: (cellProps) => (
        // capitalize(cellProps.status)
        <span className="d-inline-block" tabIndex="0" data-toggle="tooltip">
          {cellProps.is_blocked? <button className="btn btn-md btn-primary " onClick={() => {
                      toggle(),setId(cellProps._id),setBlocked(false);
                    }} >
            Unblock 
          </button>:<button className="btn btn-md btn-danger " onClick={() => {
                      toggle(),setId(cellProps._id),setBlocked(true);
                    }} >
            Block 
          </button>}
         
        </span>
      ),
    }
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Customer" />
          <Row>
            <DatatableTables columns={columns} data={customers} hiddenColumns={["_id"]} />
          </Row>
        </Container>
      </div>
      <Modal className="modal-dialog-centered"
                isOpen={modal}
                toggle={() => {
                  toggle();
                }}
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                   {blocked?'Block' : 'Unblock' } customer
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      toggle();
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">

                  <p>
                    Are you sure you want to {blocked ? 'block' : 'unblock'} this customer?
                  </p>

                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => {
                      toggle();
                    }}
                    className="btn btn-primary "
                    data-dismiss="modal"
                  >
                    No
                  </button>
                  <button
                    onClick={() => blockedCustomer(id)}
                    type="button"
                    className="btn btn-danger "
                  >
                    Yes
                  </button>
                </div>
              </Modal>
    </React.Fragment>
  )
}

export default customer
