import React from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/images/favicon.png";

const Header = props => {
  const history = useHistory()

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex w-100">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img width="70" height="80" src= '\logo.png' alt="Logo" />
                </span>
                <span className="logo-lg">
                  <img width="70" height="80" src= '\favicon.png' alt="Logo" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img width="70" height="80" src='\favicon.png' alt="Logo" />
                </span>
              </Link>
            </div>
            <div className="d-flex justify-content-between w-100">
              <button
                type="button"
                onClick={() => {
                  tToggle();
                }}
                color="white"
                style={{color:'white'}}
                className="btn btn-sm px-3 font-size-16 header-item "
                id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars" />
              </button>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    localStorage.clear()
                    sessionStorage.clear()
                    history.push("/")
                  }}
                  style={{backgroundColor:'white',color:'black'}}
                  className="btn btn-md btn-primary mt-3"
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
