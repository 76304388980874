import React from "react";
import TableContainer from '../Common/TableContainer';

function DatatableTables({ columns, data, hiddenColumns }) {
    return (
        <div className="container-fluid">
            <TableContainer
                columns={columns}
                data={data || []}
                hiddenColumns={hiddenColumns || []}
                isGlobalFilter={true}
                isAddOptions={true}
                customPageSize={10}
                className="custom-header-css"
            />
        </div>
    );
}

export default DatatableTables;