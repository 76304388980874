import React, { useEffect } from "react";
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    Input,
    Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications"
import moment from "moment";
import axios from "axios";
import Appconfig from "constants/config";

const EditChef = ({ chef, toggle, isOpen, getChefs }) => {
    const { addToast } = useToasts();

    const handleSubmit = async (values) => {
        try {
            values.id = chef._id
            values.profile_img = chef.profile_img
            values.cuisine = chef.cuisine
            const { data } = await axios.post(`${Appconfig.baseurl}/chefs/update`, values, {
                headers: { token: localStorage.getItem("token") }
            });
            if (!data.error) {
                addToast("Chef updated successfully", { appearance: 'success', autoDismiss: true });
                formik.resetForm()
                getChefs()
                toggle()
            } else {
                addToast(data.title || "Something went wrong", { appearance: 'error', autoDismiss: true });
            }
        } catch (error) {
            addToast(error.message || "Something went wrong", { appearance: 'error', autoDismiss: true });
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            email: '',
            phone_no: '',
            dob: '',
            gender: '',
            location: '',
            address: '',
            visit_rate: '',
            experience: '',
            marital_status: '',
            about: '',
            relocate: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please enter your name"),
            email: Yup.string().email("Plese enter valid email").required("Please enter your email"),
            phone_no: Yup.string().min(10).max(10).required("Please enter your phone number"),
            dob: Yup.date().required("Please enter your date of birth"),
            gender: Yup.string().required("Please enter your gender"),
            location: Yup.string().required("Please enter your location"),
            address: Yup.string().required("Please enter your address"),
            visit_rate: Yup.number().required("Please enter your visit rate"),
            experience: Yup.number().required("Please enter your experience"),
            marital_status: Yup.string().required("Please enter your marital status"),
            about: Yup.string().required("Please enter about you"),
            relocate: Yup.string().required("Please enter your relocate"),
        }),
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        formik.setValues({
            name: chef && chef.name ? chef.name : '',
            email: chef && chef.email ? chef.email : '',
            phone_no: chef && chef.phone_no ? chef.phone_no : '',
            dob: chef && chef.dob ? moment(chef.dob).format('YYYY-MM-DD') : '',
            gender: chef && chef.gender ? chef.gender : '',
            location: chef && chef.location ? chef.location._id : '',
            address: chef && chef.address ? chef.address : '',
            visit_rate: chef && chef.visit_rate ? chef.visit_rate : '',
            experience: chef && chef.experience ? chef.experience : '',
            marital_status: chef && chef.marital_status ? chef.marital_status : '',
            about: chef && chef.about ? chef.about : '',
            relocate: chef && (chef.relocate == true || chef.relocate == false) ? chef.relocate : '',
        })
    }, [chef])

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
                Edit User
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={formik.handleSubmit}>
                    <Row form>
                        <Col xs={12}>
                            <div className="mb-3">
                                <Label className="form-label">Name</Label>
                                <Input
                                    name="name"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.name || ""}
                                    invalid={formik.touched.name && formik.errors.name}
                                />
                                {formik.touched.name &&
                                    formik.errors.name ? (
                                    <FormFeedback type="invalid">
                                        {formik.errors.name}
                                    </FormFeedback>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Email</Label>
                                <Input
                                    name="email"
                                    label="Email"
                                    type="email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email || ""}
                                    invalid={formik.touched.email && formik.errors.email}
                                />
                                {formik.touched.email &&
                                    formik.errors.email ? (
                                    <FormFeedback type="invalid">
                                        {formik.errors.email}
                                    </FormFeedback>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Phone</Label>
                                <Input
                                    name="phone_no"
                                    label="Phone"
                                    type="number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.phone_no || ""}
                                    invalid={formik.touched.phone_no && formik.errors.phone_no}
                                />
                                {formik.touched.phone_no &&
                                    formik.errors.phone_no ? (
                                    <FormFeedback type="invalid">
                                        {formik.errors.phone_no}
                                    </FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="text-end">
                                <button
                                    type="submit"
                                    className="btn btn-success save-user"
                                >
                                    Save
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default EditChef;
