import React from "react";
import { Row, Col, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import { useHistory } from 'react-router-dom'
import axios from "axios";
import Appconfig from "constants/config";
import { useToasts } from 'react-toast-notifications'

const ForgetPassword = props => {
  document.title = "Forget Password | FindMyChef";
  const { addToast } = useToasts();
  const history = useHistory();

  const handleSubmit = async (values) => {
    try {
      const { data } = await axios.post(`${Appconfig.baseurl}/admin/forgot_password`, values);
      if (!data.error) {
        localStorage.setItem('temp_token', data.token)
        addToast('OTP sent successfully', { appearance: 'success', autoDismiss: true });
        history.push(`/verify-otp`)
      } else {
        addToast(data.title || "Something went wrong", { appearance: 'error', autoDismiss: true });
      }
    } catch (error) {
      addToast(error.message || "Something went wrong", { appearance: 'error', autoDismiss: true });
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Please Enter Valid Email").required("Please Enter Your Email"),
    }),
    onSubmit: handleSubmit
  });

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={formik.handleSubmit}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email || ""}
                          invalid={
                            formik.touched.email && formik.errors.email ? true : false
                          }
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col>
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                            Send OTP
                          </button>
                        </Col>
                      </Row>
                      <p>
                        Go back to{" "}
                        <Link to="login" className="font-weight-medium text-primary">
                          Login
                        </Link>{" "}
                      </p>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ForgetPassword;
