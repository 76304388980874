import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Form, Row, Input, Label, FormFeedback } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import logodark from "../../assets/images/logo.png";
import logolight from "../../assets/images/logo footer.png";
import CarouselPage from "./CarouselPage";
import { useHistory } from 'react-router-dom'
import axios from "axios";
import Appconfig from "constants/config";
import { useToasts } from 'react-toast-notifications'

const Login = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  document.title = "Login | FindMyChef-Admin";

  const handleLogin = async (values) => {
    try {
      const { data } = await axios.post(`${Appconfig.baseurl}/admin/login`, values);
      if (!data.error) {
        localStorage.setItem('token', data.token)
        addToast('User logged in successfully', { appearance: 'success', autoDismiss: true });
        history.push(`/customer`)
      } else {
        addToast(data.title || "Something went wrong", { appearance: 'error', autoDismiss: true });
      }
    } catch (error) {
      addToast(error.message || "Something went wrong", { appearance: 'error', autoDismiss: true });
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: handleLogin
  });

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />
            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      {/* <Link to="/" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="50"
                          className="auth-logo-dark"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="50"
                          className="auth-logo-light"
                        />
                      </Link> */}
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Welcome Back  !</h5>
                        <p className="text-muted">
                          Sign in to continue.
                        </p>
                      </div>
                      <div className="mt-4">
                        <Form className="form-horizontal" onSubmit={formik.handleSubmit} >
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.email || ""}
                              invalid={
                                formik.touched.email && formik.errors.email ? true : false
                              }
                            />
                            {formik.touched.email && formik.errors.email ? (
                              <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              value={formik.values.password || ""}
                              type="password"
                              placeholder="Enter Password"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              invalid={
                                formik.touched.password && formik.errors.password ? true : false
                              }
                            />
                            {formik.touched.password && formik.errors.password ? (
                              <FormFeedback type="invalid">{formik.errors.password}</FormFeedback>
                            ) : null}
                          </div>
                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block "
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>
                        </Form>
                        <div className="mt-3">
                          <Link to="/forgot-password"  >Forgot password</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
