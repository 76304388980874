import React from "react";
import { Redirect } from "react-router-dom";
import ForgetPassword from "pages/Authentication/ForgetPassword";
import Login from "pages/Authentication/Login";
import Pages404 from "pages/Utility/pages-404";
import Pages500 from "pages/Utility/pages-500";
import Customers from "pages/Customer";
import Chefs from "pages/Chefs";
import Bookings from "pages/Bookings";
import Transactions from "pages/Transacations";
import Jobs from "pages/Jobs";
import VerifyOtp from "pages/Authentication/VerifyOtp";


const authProtectedRoutes = [
  { path: "/customer", component: Customers },
  { path: "/chefs", component: Chefs },
  // { path: "/bookings", component: Bookings },
  { path: "/jobs", component: Jobs },
  // { path: "/transactions", component: Transactions },
  { path: "/", exact: true, component: () => <Redirect to="/customer" /> },
];

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPassword },
  { path: "/verify-otp", component: VerifyOtp },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
];

export { authProtectedRoutes, publicRoutes };
